export var languages = [
	{
		name: "French",
		code: "fr-FR"
	},
	{
		name: "German",
		code: "de-DE"
	},
	{
		name: "Spanish",
		code: "es-ES"
	},
	{
		name: "Italian",
		code: "it-IT"
	},
	{
		name: "Japanese",
		code: "ja-JP"
	},
	{
		name: "Korean",
		code: "ko-KR"
	},
	{
		name: "Dutch",
		code: "nl-NL"
	},
	{
		name: "Portuguese (Brazil)",
		code: "pt-BR"
	},
	{
		name: "Portuguese (Portugal)",
		code: "pt-PT"
	},
	{
		name: "Russian",
		code: "ru-RU"
	},
	{
		name: "Turkish",
		code: "tr-TR"
	},
	{
		name: "Chinese (Simplified)",
		code: "zh-CN"
	},


]