import React from 'react';
import { ArrowDownTrayIcon, GlobeAsiaAustraliaIcon, CodeBracketIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

const CourseLink = ({ course }) => {
	return ( 
		<>
			<div className=" bg-slate-300 my-2 rounded-md p-3 relative">
				
				<h3 className="font-bold">{course.name}</h3>
				{course.downloadsAvailable && (
					<>
						<a href={`https://dev.cloudproduct.info/jlr/${course.link}/SCORM-Package.zip`}>
							<ArrowDownTrayIcon className="h-5 mx-2 float-right top-2 cursor-pointer text-indigo-700 hover:text-indigo-400" title="SCORM Package"/>
						</a>
						<a href={`https://dev.cloudproduct.info/jlr/${course.link}/ForTranslation.zip`}>
							<CodeBracketIcon className="h-5 mx-2 float-right top-2 cursor-pointer text-indigo-700 hover:text-indigo-400" title="Files for Translation"/>
						</a>
					</>
				)}
				{course.translated && (
					<Link to={`/${course.link}/links`} >
						<GlobeAsiaAustraliaIcon className="h-5 mx-2 float-right top-2 cursor-pointer text-indigo-700 hover:text-indigo-400" title="Language Versions" />
					</Link>
				)}
				<a target="_blank" href={`https://dev.cloudproduct.info/jlr/${course.link}/`} className=" text-indigo-700 hover:text-indigo-400 font-semibold">{`https://dev.cloudproduct.info/jlr/${course.link}/`}</a>
			</div>
		</>
	 );
}
 
export default CourseLink;