import React from 'react';
import Page from '../components/layout/Page';
import { courses } from '../config/courses';
import Link from '../components/Link';
import CourseLink from '../components/CourseLink';
const Home = () => {
	return ( 
		<Page>
			<h1 className="text-4xl font-semibold">Courses</h1>
			{courses.map(course => (
				<CourseLink course={course} />
			))
			}
		</Page>
	 );
}
 
export default Home;