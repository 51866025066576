import React from 'react';
import { Link, useParams } from 'react-router-dom';
const Breadcrumb = () => {
	const { courseName } = useParams();
	return ( 
		<div className="bg-slate-200 p-2 rounded-md mb-3">
			<Link className=" text-indigo-700 hover:text-indigo-400 font-semibold" to={'/'}>Home</Link>
			{courseName && (
				<>
					<span> /</span> {courseName} / Links
				</>
			)}
		</div>
	 );
}
 
export default Breadcrumb;