import React from 'react';
import Breadcrumb from '../Breadcrumb';

const Page = ({ children }) => {
	return (
		<>
			<div className="md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
				<div className="flex min-h-0 flex-1 flex-col bg-indigo-700">
					<div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
						<div className="mx-4 mr-10 flex flex-col">
							<p className="my-8 text-2xl font-medium text-white">Semcon</p>
						</div>
					</div>
				</div>
			</div>
			<div className="flex h-screen flex-1 flex-col bg-gray-100 md:pl-64">
				<main className="relative flex-1 p-5">
					<Breadcrumb />
					{children}
				</main>
			</div>
		</>
	);
}

export default Page;