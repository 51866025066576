import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Links from './pages/Links';
import Home from './pages/Home';

function App() {
  return (
    <BrowserRouter>
		<Routes>
			<Route path="/" element={<Home />}/>
			<Route path="/:courseName/links" element={<Links />}/>
		</Routes>
	</BrowserRouter>
  );
}

export default App;
