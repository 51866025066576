export var courses = [
	{
		name: "Battery Handling - Module 3",
		link: "bh-module3",
		translated: true,
		downloadsAvailable: true
	},
	{
		name: "Battery Handling - Module 4",
		link: "bh-module4",
		translated: true,
		downloadsAvailable: true
	},
	{
		name: "Battery Handling - Module 5",
		link: "bh-module5",
		translated: true,
		downloadsAvailable: true
	},
	{
		name: "Battery Handling - Module 6",
		link: "bh-module6",
		translated: true,
		downloadsAvailable: true
	}
]