import React from 'react';
import Page from '../components/layout/Page';
import { languages } from '../config/languages';
import CourseLink from '../components/CourseLink';
import Link from '../components/Link';


const Links = ({ }) => {
	
	return ( 
		<Page>
			<h1 className="text-4xl font-semibold">Languages</h1>
			{ languages.map(lang => (
				<div>
					<Link language={lang} />
				</div>
			))}
		</Page>
	 );
	
}
 
export default Links;