import React from 'react';
import { useParams } from 'react-router-dom';
import { ArrowDownTrayIcon, GlobeAsiaAustraliaIcon } from '@heroicons/react/24/outline';

const Link = ({ language }) => {
	const { courseName } = useParams();
	return(
		<>
			<div className=" bg-slate-300 rounded-md p-3 my-2 relative">
				
				<h3 className="font-bold">{language.name}</h3>
				<a href={`https://dev.cloudproduct.info/jlr/${courseName}/${language.code}/SCORM-Package.zip`}>
					<ArrowDownTrayIcon className="h-5 float-right right-2 top-2 cursor-pointer text-indigo-700 hover:text-indigo-400" title="SCORM Package"/></a>
					
				<a target="_blank" href={`https://dev.cloudproduct.info/jlr/${courseName}/${language.code}/`} className=" text-indigo-700 hover:text-indigo-400 font-semibold">{`https://dev.cloudproduct.info/jlr/${courseName}/${language.code}/`}</a>
			</div>
		</>
	);
}

export default Link;